import { sendAnalyticsEvent } from 'utils/datalayer';
import { Product as ProductCore } from '@core/domain/models/product';
import Service from '@core/data/service/service';
import { getFormattedPricesGateway, mountPayloadProductsOffer } from '@core/presentation/utils/prices';
import config from '../../env.config';
import { getRemoteAddr } from './string';
import { publicIpv4 } from 'public-ip';

export const productsArray = (products : Product[] | ProductCore[], discount = false) => {
  const productIdsArray = products.map(product => {
    const { price, offer, coupons, productId } = product || {};

    //  send only products with price not defined
    if (!price) {
      if (coupons && coupons.length >= 1) {
        if (offer) {
          return {
            productId: `${productId || ''}`,
            offerKey: `${offer || ''}`,
            couponOfferCode: `${!discount ? '' : coupons[0]?.code}`
          };
        }
        return {
          productId: `${productId || ''}`,
          couponOfferCode: `${!discount ? '' : coupons[0]?.code}`
        };
      }

      if (offer) {
        return {
          productId,
          offerKey: `${offer || ''}`
        };
      }

      return {
        productId
      };
    }

    return undefined;
  });

  return productIdsArray.filter(product => product?.productId);
};

export const getPriceTotal = async (products, ip = null) => {
  if(!ip)
    ip = getRemoteAddr(await publicIpv4())

  const productsPrice = []
  const payloadProducts = mountPayloadProductsOffer(products, ip);

  try {
    const producstList = await Service.post(
      `${config.envConfig.PRICES}/public/v1/payment/checkout/load-products`,
      payloadProducts
    );

    products.map(product => {
      if(producstList?.productsResponse?.length) {
          product.price = getFormattedPricesGateway(
            producstList,
            product
          );
      } else {
        sendAnalyticsEvent({
          event: 'noninteraction',
          event_category: 'Product:NoPrice:Single',
          event_label: `productId-${product.productId}`
        });
      }
    });
  } catch (error) { console.error('error on getCheckoutPrice request ==> ', error)}

  return productsPrice;
};
