import { Product } from '@core/domain/models/product';
import {
  PaymentOptions,
  PaymentMethod,
  checkoutPaymentOptions
} from '@core/domain/constants/payments-options';

export const mountPayloadProductsOffer = (
  products: Product[],
  ip: string,
  useCoupon?: { coupon: boolean }
) => {
  const producstOfferList = products.map(product => {
    const { price, offer, coupons } = product || {};

    if (!price) {
      return {
        products: [
          {
            offer: `${offer || ''}`,
            ...(useCoupon?.coupon && coupons?.length
              ? { coupon: coupons[0]?.code }
              : {})
          }
        ],
        additionalData: {
          shopperIp: ip
        }
      };
    }

    return null;
  });

  return producstOfferList.filter(
    producstOffer => producstOffer?.products[0].offer
  );
};

const getProductOfferByType = (offers, type) => {
  return offers?.length ? offers.find(item => item.type === type) : {};
};

const formattedPriceProduct = ({
  locale,
  amount
}) => {
  if (!amount?.currency) {
    return null;
  }

  const { currency, value } = amount;
  const formattedLocale = locale || navigator.language || checkoutPaymentOptions.LOCALE.PT;
  const formattedValue = value;
  const sanitezedLocale = formattedLocale.replace('_', '-')
  const formatedCuurency = Intl.NumberFormat(sanitezedLocale, {
    style: 'currency',
    currency,
  })

  return formatedCuurency.format(Number(formattedValue));
};

export const getFormattedPriceProduct = (
  offers: PaymentMethod[],
  locale: string
) => {
  const productCreditCardOffer = getProductOfferByType(
    offers,
    PaymentOptions.CREDIT_CARD
  );
  let price = '';

  if (productCreditCardOffer?.amount && Object.keys(productCreditCardOffer.amount)?.length > 0) {
    price = formattedPriceProduct({ locale, amount: productCreditCardOffer.amount });
  }

  return price;
};

export const getFormattedPricesGateway = (producstList, product) => {
  let price = '';

  if (producstList?.productsResponse?.length) {
    const productOfferPriceDiscount = producstList?.productsResponse?.filter(
      productPrice => product.productId === productPrice?.products[0].id
    );

    price = getFormattedPriceProduct(
      productOfferPriceDiscount[0]?.products[0]?.offer?.paymentMethods,
      productOfferPriceDiscount[0]?.locale
    );
  }

  return price;
};

export const mountListProductsPrice = (
  productsList,
  productsListPrice,
  locale
) => {
  return productsList.map(product => {
    const productOfferPrice = productsListPrice?.productsResponse?.filter(
      productPrice => product.productId === productPrice?.products[0].id
    );

    if (Array.isArray(productOfferPrice) && productOfferPrice[0]?.products?.length) {
      // eslint-disable-next-line no-param-reassign
      product.price = getFormattedPriceProduct(
        productOfferPrice[0]?.products[0]?.offer?.paymentMethods,
        locale
      );
    }

    return product;
  });
};
